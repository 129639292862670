// extracted by mini-css-extract-plugin
export const sectionSpacing = "custom-auth-module--section-spacing--1o_nZ";
export const rowSpacing = "custom-auth-module--row-spacing--299UA";
export const rowSpacingSml = "custom-auth-module--row-spacing-sml--3Sb7v";
export const emulator = "custom-auth-module--emulator--1Cp2U";
export const sign = "custom-auth-module--sign--2MC47";
export const emuWrapper = "custom-auth-module--emuWrapper--2K-xw";
export const videoFeed = "custom-auth-module--videoFeed--2v0y3";
export const emuBox = "custom-auth-module--emuBox--8j5fw";
export const messageBox = "custom-auth-module--messageBox--3B39m";
export const authForm = "custom-auth-module--authForm--1OHhF";