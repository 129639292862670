// import dependencies
import React from "react";

// import components
import CustomAuth from "../components/auth/custom-auth";
import Layout from "../components/layout";
import Section from "../components/section";
import SeparatorTopGrey from "../components/separators/separator-top-grey";

export default function EmuladorPage () {
    return (
        <Layout>
            <Section color={'white'}>
                <CustomAuth />
                <SeparatorTopGrey />
            </Section>
        </Layout>
    );
}