// import dependencies
import React from "react";

// import styles and assets
import * as styles from "./styles/video-post.module.scss";

export default function VideoPost({children, videoUrl}) {
    return (
<div className={`video-post ${styles.VideoPost}`}>
    <iframe
        title="video post"
        src={videoUrl}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
    ></iframe>
    <div className="videoText">
        {children}
    </div>
</div>
    );
}