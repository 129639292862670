// import dependencies
import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
// import DatePickerField from "./date-picker";

// import styles and assets
import * as styles from "../styles/auth-forms.module.scss";

export default function FormSignUp(props) {

    // ----------------
    // form validations
    // ----------------
    function validateName(value) {
        let error,
            format = /[^a-zA-Z\s\-/]/;
                
        if (!value) {
            error = "El nombre es requerido";
        } else if (format.test(value)) {
            error = "El nombre no puede tener números y caracteres especiales";
        }
        
        return error;
    }
    
    function validateEmail(value) {
        let error,
            format = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        if (!value) {
            error = "El email es requerido";
        } else if (!format.test(value)) {
            error = "Dirección de email inválida";
        }
        
        return error;
    }

    function validateAge(value) {
        let error,
            format = /^\d+$/;
                
        if (!value) {
            error = "La edad es requerida, no puede tener espacios";
        } else if (!format.test(value)) {
            error = "La edad solo puede tener números, sin espacios";
        }
        
        return error;
    }

    function validateGender(value) {
        let error;
    
        if (!value || value === "elige") {
            error = "El género es requerido";
        }
        
        return error;
    }

    function validatePassword(value) {
        let error,
            format = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
        
        if (!value) {
            error = "La contraseña es requerida";
        } else if (!format.test(value)) {
            error = "La contraseña debe tener un largo entre 8 y 20 caracteres, a lo menos un número y una letra mayúscula y una minúscula";
        }
        
        return error;
    }

    return (
        <Formik
            initialValues = {{
                name: "",
                username: "",
                age: "",
                gender: "",
                password: ""
            }}

            onSubmit = {(values, {setSubmitting}) => {
                props.submitFunc();
                setSubmitting(false);
            }}
        >
            <Form>
                <fieldset>
                    <label htmlFor="name">Nombre</label>
                    <Field id="Name" className={styles.formField} type="text" name="name" validate={validateName} onBlur={props.onChangeFunc}/>
                    <ErrorMessage name="name" component="span" />
                    <label htmlFor="username">Email</label>
                    <Field id="UserEmailName" className={styles.formField} type="email" name="username" validate={validateEmail} onBlur={props.onChangeFunc}/>
                    <ErrorMessage name="username" component="span" />
                    <label htmlFor="age">Edad</label>
                    <Field id="Age" className={styles.formField} type="number" name="age" validate={validateAge} onBlur={props.onChangeFunc}/>
                    <ErrorMessage name="age" component="span" />
                    <label htmlFor="gender">Género:</label>
                    <Field name="gender" className={styles.formField} as="select" validate={validateGender} onBlur={props.onChangeFunc}>
                        <option value="elige">Elige una opción</option>
                        <option value="femenino">Femenino</option>
                        <option value="masculino">Masculino</option>
                        <option value="otro">Otro</option>
                        <option value="noEspecifica">Prefiero no decir</option>
                    </Field>
                    <ErrorMessage name="gender" component="span" />
                    <label htmlFor="password">Contraseña</label>
                    <Field name="password" className={styles.formField} type="password" validate={validatePassword} onBlur={props.onChangeFunc}/>
                    <ErrorMessage name="password" component="span" />
                </fieldset> 
                <fieldset>
                    <button className={styles.formButton} type="submit">
                        Regístrate
                    </button>
                    <button className={styles.formButton} onClick={() => props.updateFormStateFunc(() => ({...props.formStateSpread, formType: "signIn"}))}>
                        Inicia sesión
                    </button>
                </fieldset>
            </Form>
        </Formik>
    );
}