// import dependencies
import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";

// import styles and assets
import * as styles from "../styles/auth-forms.module.scss";

export default function FormConfirmSignUp(props) {

    // ----------------
    // form validations
    // ----------------
    function validateEmail(value) {
        let error,
            format = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        if (!value) {
            error = "El email es requerido";
        } else if (!format.test(value)) {
            error = "Dirección de email inválida";
        }
        
        return error;
    }

    function validatePassword(value) {
        let error,
            format = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
        
        if (!value) {
            error = "La contraseña es requerida";
        } else if (!format.test(value)) {
            error = "La contraseña debe tener un largo entre 8 y 20 caracteres, a lo menos un número y una letra mayúscula y una minúscula";
        }
        
        return error;
    }

    return (
        <Formik
            initialValues = {{
                username: "",
                password: ""
            }}

            onSubmit = {(values, {setSubmitting}) => {
                props.submitFunc();
                setSubmitting(false);
            }}
        >
            <Form>
                <fieldset>
                    <label htmlFor="username">Email</label>
                    <Field id="UserEmailName" className={styles.formField} type="email" name="username" validate={validateEmail} onBlur={props.onChangeFunc}/>
                    <ErrorMessage name="username" component="span" />
                    <label htmlFor="password">Contraseña</label>
                    <Field name="password" className={styles.formField} type="password" validate={validatePassword} onBlur={props.onChangeFunc}/>
                    <ErrorMessage name="password" component="span" />
                </fieldset>
                <fieldset>
                    <button className={styles.formButton} type="submit">
                        Iniciar sesión
                    </button>
                    <button className={styles.formButton} onClick={
                        () => props.updateFormStateFunc(() => ({...props.formStateSpread, formType: "signUp"}))
                    }>Regístrate</button>
                </fieldset>
            </Form>
        </Formik>
    );
}