// import dependencies
import React, { useState, useRef, useEffect } from "react";
import {StaticQuery, graphql} from "gatsby";
import {Auth, Hub, API} from "aws-amplify";

// import components
import FormConfirmSignUp from "../forms/form-confirm-sign-up";
import FormSignIn from "../forms/form-sign-in";
import FormSignUp from "../forms/form-sign-up";
import VideoPost from "../video-post";

// import styles and assets
import * as styles from "../styles/custom-auth.module.scss";

export default function CustomAuth() {
    // ----
    // refs
    // ----
    // ref to store the iframe for the emulator
    const emuIframe = useRef(null);
    
    // var to store the user current game state
    let usrGameState = {};

    // ------
    // states
    // ------
    // define initial form state
    const initialFormState = {
        username: "",
        password: "",
        email: "",
        age: "",
        gender: "",
        authCode: "",
        formType: "signUp"
    }

    // define variables
    const [formState, updateFormState] = useState(initialFormState);
    const [user, setUser] = useState({});
    const [gameId, setGameId] = useState("");

    // ------------
    // side effects
    // ------------
    useEffect(() => {
        const checkUser = async () => {
            try {
                const authUser = await Auth.currentAuthenticatedUser();
                setUser(authUser);
                updateFormState((formState) => ({...formState, formType: "signedIn"}));
                // console.log("check user user: ", authUser);
                // console.log("USER: ", user);
            } catch (err) {
                setUser(null);
            }    
        };

        const setAuthListener = async () => {
            Hub.listen("auth", (data) => {
                switch (data.payload.event) {
                    case "signOut":
                        // console.log("data from event: ", data);
                        updateFormState((formState) => ({...formState, formType: "signUp"}));
                        break;
                    default:
                        break;
                }
            });
        };

        // ---------------
        // event listeners
        // ---------------

        // receive messages from the iframe 
        window.addEventListener("message", (event) => {
            // console.log("data from iframe on parent");
            // console.log("event origin: ", event.origin);

            // return if the message origin is not the same page
            if ((event.origin !== "http://localhost:8000") && 
                (event.origin !== "https://release-candidate.d1d2spnu2nly1g.amplifyapp.com") &&
                (event.origin !== "https://www.ingeniosamente.org")) {
                console.log("EVENT ORIGIN - ", event.origin);
                console.log("iframe message not from origin page");
                return;
            }

            if (event.data[0] === "saveData") {
                // console.log("Save action with data:", event.data[1]);
                console.log("EMULATOR - SAVING DATA");
                saveUserData(event.data[1]);
            } else if (event.data[0] === "loadData") {
                console.log("Load action");
            }
        }, false);

        checkUser();
        setAuthListener();

        // triggers when the component unmounts
        return () => {
            // sign out user on unmount
            Auth.signOut();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
    // -----------------------
    // state variables setters
    // -----------------------
    function updateGameId() {
        setGameId(usrGameState.id);
    }

    // -------------------------------------------
    // send message to iframe holding the emulator
    // -------------------------------------------
    function sendIframeMessage(message) {
        // console.log("send data to iframe");
        emuIframe.current.contentWindow.postMessage(message);
    };

    // ------------
    // API Handling
    // ------------

    // API search if the user has a game state saved
    // this returns the user data with the game state string
    async function checkGameState(requestData) {
        await API.post("emuladorapi", "/emulador/search", requestData)
        .then((response) => {
            // console.log("Search response:", response);
            usrGameState = response;
        })
        .catch((error) => {
            console.log(error);
        });
    }

    // API post a new user game state 
    // this returns the new user data with the game state string
    async function postNewGameState(requestData) {
        await API.post("emuladorapi", "/emulador/insert", requestData)
        .then((response) => {
            // console.log("Insert response:", response);
            usrGameState = response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
    }

    // API update a user game state 
    // this returns the user updated data with the game state string
    async function updateGameState(requestData) {
        await API.post("emuladorapi", "/emulador/update", requestData)
        .then((response) => {
            // console.log("Update response:", response);
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
    }

    // API post a new user reg
    // this returns the API response
    async function postNewUsrReg(_formState) {
        // set the date of the register
        let d = new Date();
        let currDate = d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();

        let requestData = {
            headers: {
            },
            body: {
                id: _formState.username,
                name: _formState.name,
                email: _formState.username,
                age: _formState.age,
                gender: _formState.gender,
                date: currDate
            }
        }

        console.log("Nuevo usuario reg data:", requestData);
        
        await API.post("usersregapi", "/usersreg/insert", requestData)
        .then((response) => {
            console.log("Register response:", response);
            return response;
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
    }

    // check if the user game data exists, if not then create a new user game data
    // after that load the user game data
    // this function asks for a (string) usermail
    async function checkUserData(usermail) {
        // get the user auth token
        const authUser = await Auth.currentAuthenticatedUser();
        const token = authUser.signInUserSession.idToken.jwtToken;

        // set up the initial request data
        let requestInfo = {
            headers: {
                Authorization: token,
            },
            body: {
                id: authUser.username
            }
        }
        // console.log("Request Info:", requestInfo);

        // ask the API if the user has already a game state
        // and store it on a variable then return the result
        await checkGameState(requestInfo);
        // console.log("Get user game state:", usrGameState);

        // check if the user data is empty
        // if it is then create a new user game state data
        // if not then pass the game state data to the emulator
        if (JSON.stringify(usrGameState) === JSON.stringify({})) {
            // set up the new user game data request
            let newEmulatorData = {
                headers: {
                    Authorization: token,
                },
                body: {
                    id: authUser.username,
                    email: usermail,
                    gameState: ""
                }
            }

            // create a new user game state
            await postNewGameState(newEmulatorData);
            // console.log("New user game state:", usrGameState);

            // -----------------------------------------
            // load the emulator without saved game data
            // -----------------------------------------
            console.log("EMULATOR - NO SAVE DATA");

            updateGameId();
        } else {
            // --------------------------------------
            // load the emulator with saved game data
            // --------------------------------------
            console.log("EMULATOR - LOADING DATA");
            console.log("user game data loaded: ", JSON.stringify(usrGameState));

            updateGameId();
            sendIframeMessage(usrGameState.gameState);
        }
    }

    // this function triggers when the iframe holding the emulator triggers
    // an event for saving the game data
    // this function asks for a (string) gameData
    async function saveUserData (gameSaveData) {
    // const saveUserData = async(gameSaveData) => {
        // get the user auth token
        const authUser = await Auth.currentAuthenticatedUser();
        const token = authUser.signInUserSession.idToken.jwtToken;

        // set up the initial request data
        let requestInfo = {
            headers: {
                Authorization: token,
            },
            body: {
                id: authUser.username,
                email: authUser.attributes.email,
                gameState: gameSaveData
            }
        }
        // console.log("Save request Info:", requestInfo);

        // send a save game state request to the API
        await updateGameState(requestInfo);
    }

    // -------------
    // form triggers
    // -------------
    function onChange(event) {
        event.persist();
        updateFormState(() => ({...formState, [event.target.name]: event.target.value}));
        // console.log("CURRENT FORM STATE:", formState);
    }

    async function signUp() {
        // const {name, username, email, birthdate, gender, password} = formState;
        const {username, email, password} = formState;
        await Auth.signUp({username, password, attributes: {email}});
        updateFormState(() => ({...formState, formType: "confirmSignUp"}));
    }

    async function confirmSignUp() {
        const {username, authCode} = formState;
        await Auth.confirmSignUp(username, authCode);
        postNewUsrReg(formState);
        updateFormState(() => ({...formState, formType: "signIn"}));
    }

    async function signIn() {
        const {username, password} = formState;
        await Auth.signIn(username, password);
        updateFormState(() => ({...formState, formType: "signedIn"}));
        checkUserData(username);
    }

    // setup form type
    const {formType} = formState;
    return(
        <div className="auth-box">
            {
                formType === "signUp" && (
                    <div className={`container ${styles.emulator}`}>
                        <div className="row row-spacing">
                            <div className="col-sm-12 col-md-6 align-self-center">
                                <h1>Laboratorio virtual STEM</h1>
                                <h4>Descubre los estados de la materia y la electrónica ¡en un solo lugar de encuentro! Con el emulador de Ingeniosas, explora y conoce herramientas utilizadas en ciencia y tecnología, pero de manera online. Te invitamos a poner en práctica la experimentación y el método de ensayo y error para conseguir grandes resultados.</h4>
                                <h4>Si aún no tienes cuenta, completa el siguiente formulario y presiona sobre el botón "Regístrate". Si ya tienes cuenta, presiona sobre "Iniciar sesión" para acceder con tu usuario y clave.</h4>
                            </div>
                            <div className="col-sm-12 col-md-6 align-self-center">
                                <div className={styles.authForm}>
                                    <FormSignUp submitFunc={signUp} onChangeFunc={onChange} updateFormStateFunc={updateFormState} formStateSpread={formState}/>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                formType === "confirmSignUp" && (
                    <div className={`container ${styles.emulator}`}>
                        <div className="row row-spacing">
                            <div className="col-sm-12 col-md-6 align-self-center">
                                <h1>Ingresa código de confirmación</h1>
                                <h4>Revisa tu email para buscar tu código de confirmación, ingresalo en el formulario para confirmar tu regístro.</h4>
                            </div>
                            <div className="col-sm-12 col-md-6 align-self-center">
                                <div className={styles.authForm}>
                                    <FormConfirmSignUp submitFunc={confirmSignUp} onChangeFunc={onChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                formType === "signIn" && (
                    <div className={`container ${styles.emulator}`}>
                        <div className="row row-spacing">
                            <div className="col-sm-12 col-md-6 align-self-center">
                                <h1>Inicio de sesión</h1>
                                <h4>Si ya estás registrada, a continuación ingresa tu usuario (email) y contraseña para entrar al Laboratorio virtual STEM. Luego presiona el botón "Iniciar sesión". Si no tienes cuenta, ingresa a "Regístrate" para crear tu cuenta.</h4>
                            </div>
                            <div className="col-sm-12 col-md-6 align-self-center">
                                <div className={styles.authForm}>
                                    <FormSignIn submitFunc={signIn} onChangeFunc={onChange} updateFormStateFunc={updateFormState} formStateSpread={formState}/>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                formType === "signedIn" && (
                    <StaticQuery
                        query = {graphql`
                            query emuladorPageQuery {
                                allSanityVlogPost(
                                    sort: {fields: publishedAt}
                                    filter: {category: {title: {eq: "Cajas STEM"}}}
                                ) {
                                    edges {
                                        node {
                                            id
                                            title
                                            video {
                                                url
                                            }
                                        }
                                    }
                                }
                            }
                        `}
                        render={data => (
                            <div className={`${styles.emulator}`}>
                                <div className={styles.sign}>
                                    <h4>AVISO: Este emulador esta en fase Alpha</h4>
                                    <p>Para ayudarnos en su desarrollo, compártenos tus comentarios en el siguiente <a href="https://forms.gle/LDieqYbyLZEZ7hdK7">formulario</a>.</p>
                                </div>
                                <div className={styles.emuWrapper}>
                                    <div className={styles.videoFeed}>
                                        {data.allSanityVlogPost.edges.map((edge) => (
                                            <VideoPost key={edge.node.id} videoUrl={edge.node.video[0].url}>
                                                <h4>{edge.node.title}</h4>
                                            </VideoPost>
                                        ))}
                                    </div>
                                    <div className={styles.emuBox}>
                                        <iframe ref={emuIframe} title="Emulador STEM" frameBorder="0" src={`/emulator-src-two/index.html?param=${gameId}`}></iframe>
                                        <button onClick={
                                            () => Auth.signOut()
                                        }>Cerrar sesión</button>
                                    </div>
                                    <div className={styles.messageBox}>
                                        <h1>Emulador no disponible en smartphones</h1>
                                        <h4>Para poder utilizar el emulador debes ingresar desde una tablet o el navegador de tu computador.</h4> 
                                    </div>
                                </div>
                            </div>
                        )}
                    />
                )
            }
        </div>
    );
};