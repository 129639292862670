// import dependencies
import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";

// import styles and assets
import * as styles from "../styles/auth-forms.module.scss";

export default function FormConfirmSignUp(props) {

    // ----------------
    // form validations
    // ----------------
    function validateAuthCode(value) {
        let error,
            format = /^[0-9]+$/;
        
        if (!value) {
            error = "El código de confirmación es requerido";
        } else if (!format.test(value)) {
            error = "El código de confirmación solo puede contener números";
        }
        
        return error;
    }

    return (
        <Formik
            initialValues = {{
                authCode: ""
            }}

            onSubmit = {(values, {setSubmitting}) => {
                props.submitFunc();
                setSubmitting(false);
            }}
        >
            <Form>
                <fieldset>
                    <label htmlFor="authCode">Código de confirmación</label>
                    <Field id="AuthCode" className={styles.formField} type="text" name="authCode" validate={validateAuthCode} onBlur={props.onChangeFunc}/>
                    <ErrorMessage name="authCode" component="span" />
                </fieldset>
                <fieldset>
                    <button className={styles.formButton} type="submit">
                        Confirmar registro
                    </button>
                </fieldset>
            </Form>
        </Formik>
    );
}